import React, { useEffect, useState } from 'react'
import filteredJson from './filteredJson'
import round from '../../../functions/round'
import StaffButton from './StaffButton'
const RaportListItem = ({ uniqueWorkers, provisio, jsonValue, title, setLockProvision }) => {

  const cvsHeader = { ID: 'ID',  Date: 'Aika', Palveluntarjoaja: 'Palveluntarjoaja',  palvelu: 'Palvelu', Tiedot: 'Tiedot', Hinta: 'Hinta', Provisio: 'Provisio', Virike: 'Virike', Yht: 'Yht.' }

  // for filttering row by worker or if it is the last item with Fixed Price
  let totalAmount = 0
  let turnoverTotal = 0
  let provisionTotal = 0
  const buttons = uniqueWorkers.map(function(worker,i){

    const[provisioWorker, setProvisoWorker] = useState(provisio)
    const [fixedPrice, setFixedPrice] = useState(0)


    useEffect(() => {setProvisoWorker(provisio)},[provisio])

    const provisionHandler = (e) => {
      // update value if number
      setProvisoWorker(isNaN(e.target.value) ? provisioWorker : e.target.value)
      setLockProvision(true)
    }
    // json data of worker
    let jsonWorker = [cvsHeader].concat(filteredJson(provisioWorker, fixedPrice, worker, jsonValue, cvsHeader))

    // pick up total amount from json data (balance of money to worker / Company)
    let workerTotal = round(jsonWorker[jsonWorker.length -1].Yht,2)
    let turnover = round(jsonWorker[jsonWorker.length -3].Hinta,2)
    let provision = round(jsonWorker[jsonWorker.length -1].palvelu,2)

    // amount of all staff
    totalAmount += workerTotal
    turnoverTotal += turnover
    provisionTotal += provision




    return(
      <div key = {worker} >
        <div className = "staffReport-list">

          <StaffButton
            title={title}
            worker={worker}
            jsonWorker = {jsonWorker}
          />

          <div>
            <input style={{ width: '50px' }} value={provisioWorker} onChange={provisionHandler}></input>  %
          </div>
          <div>
            <input style={{ width: '50px'  }} value={fixedPrice} onChange={ (e) => {setFixedPrice(!isNaN(e.target.value) ? e.target.value : fixedPrice)}}></input>€
          </div>
          <div style={{ width: '100px', textAlign: 'right' }}><b>{workerTotal} €</b></div>
        </div>
        {uniqueWorkers.length - 1 === i &&
        <div>

          <hr/>
          <div style={{ textAlign: 'right' }}><b>Yhteensä: {round(totalAmount,2)} €</b></div>
          <div style={{ textAlign: 'right' }}><b>Liikevaihto: {round(turnoverTotal,2)} €</b></div>
          <div style={{ textAlign: 'right' }}><b>Provisiotulot: {round(provisionTotal,2)} €</b></div>
        </div>
        }
      </div>
    )})
  return buttons
}
export default RaportListItem