import React, { useState } from 'react'
import'./styles.css'
import Button from '../../components/UI/Button/Button'
import Title from '../../components/UI/Title/Title'
import RaportHelp from './components/RaportHelp'
import findUniqueNames from './components/findUniqueNames'
import csvToArr from './components/csvToArr'
import RaportList from './components/RaportList'

function StaffRaports() {

  // time month ago
  var d = new Date()
  d.setMonth(d.getMonth() - 1)
  const monthAgo = d.getFullYear() + '-' + (Number(d.getMonth()) + 1)

  // states and variables
  const[jsonValue, setJsonValue] = useState([])
  const[file, setFile] = useState()
  const[title, setTitle] = useState(monthAgo)
  const[showInfo, setShowInfo] = useState(false)
  const[provisio, setProvisio] = useState(25)
  const [lockProvision, setLockProvision] = useState(false)

  const submitHandler = (e) => {
    e.preventDefault()
    // if state is not empy, it will crash the program
    setJsonValue([])
    let compinedArray = []
    // make array from filelist so it can be looped
    const fileArray = Array.from(file)
    console.log(fileArray)
    try{

      for (let i = 0; i < fileArray.length; i++) {
        const reader = new FileReader()
        reader.onload = function (e) {
          let csvArray = csvToArr(e.target.result, ';')
          // udate state afetr making csv to array
          compinedArray = compinedArray.concat(csvArray)
          // react state allows to update just one time inside loop so doing it in last round
          // nicer solution would be to make function which returns the value..
          i === file.length - 1 &&  setJsonValue(compinedArray)
        }
        reader.readAsText(file[i])
      }

    }
    catch(e){
      // if no csv file is choosen, hit with alert message
      console.log(e)
      alert('Valitse tiedosto')
    }
  }
  console.log(jsonValue)
  const uniqueWorkers = findUniqueNames(jsonValue)

  const provisionHandler = (e) => {
    setProvisio(!isNaN(e.target.value) && !lockProvision ? e.target.value : provisio )
  }


  return (
    <div className="container">
      <Title text="Fysio Up – Rapsatin" icon="V" />

      { showInfo && <RaportHelp/>}

      <h2 style={{ textAlign: 'center', fontSize: '20px' }}>Huom! Ladattavaan <i>Rapsaan</i> ei ole asetettu laskukaavoja.</h2>
      <p className="mp-mt-0 mp-mb-20">Jos sitä pitää editoida, on laskukaavat asetettava itse. Tarkista aina raportti, että siinä on kaikki oikein. Voit valita myös useamman tiedoston kerrallaan, niin ohjelma yhdistää ne.</p>
      <form>
        <label>Mille kuukaudelle raportti tehdään. ( vuosi-kk ) </label>
        <input value={title} onChange = {(e) => {setTitle(e.target.value)}}placeholder="Vuosi-kk"></input>
        <label>Provisio %</label>
        <input style={{ opacity: lockProvision ? 0.3 : 1 }}value={provisio} onChange={provisionHandler}/>
        <input type="file" onChange={(e) => {setFile(e.target.files)}} id="csvInput" multiple = {true} accept=".csv" />
        <div className="buttons-row">
          <Button className="buttonGreen" type="submit" label="Lähetä" onClick={submitHandler}/>
          <Button
            className ="buttonGreen"
            label={showInfo ? 'Piilota ohjeet' : 'Näytä ohjeet'}
            onClick={(e) => {
              e.preventDefault()
              setShowInfo(showInfo ? false : true)
            }}/>
        </div>
      </form>

      {/* Raports list */}
      {
        jsonValue.length > 0 && uniqueWorkers &&
          <RaportList
            uniqueWorkers = {uniqueWorkers}
            provisio  = {provisio}
            jsonValue ={jsonValue}
            title = {title}
            setLockProvision = {setLockProvision}
          />
      }


    </div>
  )
}

export default StaffRaports
